<template>
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="基本信息" name="first">
        <add-or-edit />
      </el-tab-pane>
      <el-tab-pane label="运营日报" name="second">
        <operation-daily v-if="activeName === 'second'" v-permission="'MechaismDetailSecondTab'" />
      </el-tab-pane>
      <el-tab-pane label="菜品管理" name="third">
        <MealFood v-if="activeName === 'third'" v-permission="'MechaismDetailThirdTab'" :org-id="$route.params.id" />
      </el-tab-pane>
      <el-tab-pane label="活动记录" name="fourth">
        <other-import-task v-if="activeName === 'fourth'" v-permission="'MechaismDetailFourthTab'" :initiator-user-id="$route.params.id" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import operationDaily from './operationDaily.vue'
import MealFood from './mealFood/index'
import AddOrEdit from './addOrEdit'
import OtherImportTask from '@/views/Pages/task/component/otherImportTask/index.vue'

export default {
  name: 'Detail',
  components: { OtherImportTask, AddOrEdit, MealFood, operationDaily },
  data() {
    return {
      activeName: 'first'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y: auto;
  .m-content{
    .mc-item{
      line-height: 30px;
      span{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
