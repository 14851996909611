<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="listQuery.param.foodTitle" placeholder="请输入名称" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="foodImgUrl" slot-scope="{row}" :src="row.foodImgUrl || ''" :src-list="[row.foodImgUrl]" />
      <template slot="operating" slot-scope="{row}">
        <el-button size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
        <el-button size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'MealFood',
  components: { Form, CustomList, ...CustomTemplate },
  props: {
    orgId: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      searchStatus: false,
      statusArray: ['下架', '正常'],
      listQuery: {
        param: {
          orgId: this.orgId
        }
      },
      tableColumns: [
        {
          prop: 'foodTitle',
          title: '名称'
        },
        {
          title: '图片',
          slot: 'foodImgUrl'
        },
        {
          prop: 'foodDesc',
          title: '描述'
        },
        {
          prop: 'foodPrice',
          title: '价格'
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.status in this.statusArray ? this.statusArray[row.status] : ''
          }
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['mealFoodPublicPageList', 'mealFoodDelete']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.mealFoodPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd(this.orgId)
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.mealFoodDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.handleSearch(true)
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
