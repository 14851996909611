<template>
  <div class="wrapper">
    <CustomForm ref="CustomForm" v-loading="loading" :form-model="formModel" :form-items="formItem" :form-rules="formRules">
      <UploadImg slot="businessLicense" slot-scope="{item}" :upload-lists="businessLicenseUploadList" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <UploadImg slot="professionalQualification" slot-scope="{item}" :upload-lists="professionalQualificationUploadList" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <UploadImg slot="photoIds" slot-scope="{item}" :upload-lists="photoIdsUploadList" :limit="6" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" />
      <CustomMap slot="customMap" ref="CustomMap" :lat="formModel.latitude" :lng="formModel.longitude" :disabled="formStatus === 'detail'" @callback="formItem.customMap.callback" />
      <div slot="address">
        <el-input
          v-model="formModel.address"
          type="text"
          :maxlength="255"
          placeholder="请输入详细地址或在地图中选择"
          clearable
          :disabled="formStatus === 'detail'"
        />
        <div style="display: flex; margin-top: 10px;">
          <el-input
            v-model="formModel.latitude"
            type="text"
            placeholder="请在地图中选择位置以获取纬度"
            disabled
            style="margin-right: 5px;"
          />
          <el-input
            v-model="formModel.longitude"
            type="text"
            placeholder="请在地图中选择位置以获取经度"
            disabled
            style="margin-left: 5px;"
          />
        </div>
      </div>
      <div slot="button" style="text-align: center;">
        <el-button v-if="formStatus !== 'detail'" type="primary" icon="fa fa-check" @click="handleSubmit">保存</el-button>
        <el-button icon="fa fa-undo" @click="back">返回</el-button>
      </div>
    </CustomForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'
import CustomForm from '@/components/CustomForm/index'
import CustomMap from '@/components/CustomMap/index'

export default {
  name: 'AddOrEdit',
  components: {
    CustomMap,
    CustomForm,
    UploadImg,
    RegionForm
  },
  data() {
    return {
      loading: true,
      businessLicenseUploadList: [],
      professionalQualificationUploadList: [],
      photoIdsUploadList: [],
      formModel: {
        beOrgId: 123,
        beOrgName: '',
        tenant: [],
        orgName: '',
        address: '',
        standard: 0,
        delivery: 0,
        deliveryFee: 0,
        status: 0,
        mainPerson: '',
        mainPersonTel: '',
        serviceTime: '',
        businessLicense: '',
        professionalQualification: '',
        longitude: 0,
        latitude: 0,
        photoIds: ''
      }
    }
  },
  computed: {
    formId() {
      return this.$route.params.id
    },
    formStatus() {
      return this.$route.params.type
    },
    formItem() {
      return {
        'orgName': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'beOrgName': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '承办机构',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'mainPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'mainPersonTel': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'delivery': {
          'elColSpan': 12,
          'component': 'CustomFormRadio',
          'label': '是否配送',
          'data': ['否', '是']
        },
        'deliveryFee': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '配送费用',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'standard': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '助餐标准',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'serviceTime': {
          'elColSpan': 12,
          'component': 'CustomFormDatePicker',
          'label': '服务时间',
          'type': 'datetime',
          'valueFormat': 'timestamp',
          'disabled': this.formStatus === 'detail'
        },
        'businessLicense': {
          'elColSpan': 12,
          'label': '营业执照',
          'slot': 'businessLicense',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'businessLicense', data[0])
            } else {
              this.$set(this.formModel, 'businessLicense', '')
            }
          }
        },
        'professionalQualification': {
          'elColSpan': 12,
          'label': '从业资质',
          'slot': 'professionalQualification',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'professionalQualification', data[0])
            } else {
              this.$set(this.formModel, 'professionalQualification', '')
            }
          }
        },
        'photoIds': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoIds',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoIds', data.join(','))
            } else {
              this.$set(this.formModel, 'photoIds', '')
            }
          }
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 24,
          'label': '地址信息',
          'slot': 'address'
        },
        'customMap': {
          'elColSpan': 24,
          'slot': 'customMap',
          'callback': data => {
            this.formModel.address = data.poiaddress
            this.formModel.latitude = data.latlng.lat
            this.formModel.longitude = data.latlng.lng
          }
        },
        'status': {
          'elColSpan': 12,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['建设中', '已建成', '营业中', '停业中']
        },
        'button': {
          'elColSpan': 24,
          'slot': 'button'
        }
      }
    },
    formRules() {
      return {
        orgName: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        beOrgName: [
          { required: true, message: '承办机构不能为空', trigger: ['blur', 'change'] }
        ],
        mainPerson: [
          { required: true, message: '联系人不能为空', trigger: ['blur', 'change'] }
        ],
        mainPersonTel: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        deliveryFee: [
          { required: true, message: '配送费用不能为空', trigger: ['blur', 'change'] }
        ],
        standard: [
          { required: true, message: '助餐标准不能为空', trigger: ['blur', 'change'] }
        ],
        serviceTime: [
          { required: true, message: '服务时间不能为空', trigger: ['blur', 'change'] }
        ],
        businessLicense: [
          { required: true, message: '营业执照不能为空', trigger: ['blur', 'change'] }
        ],
        professionalQualification: [
          { required: true, message: '从业资质不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '地址信息不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!this.formModel.latitude || !this.formModel.longitude) {
                callback(new Error('坐标信息不能为空'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created() {
    this.initData().then(() => {
      this.$nextTick(() => {
        this.$refs.CustomMap.init()
        this.$refs.RegionForm.initRegion()
      })
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.closeLoading()
    }).catch(() => {
      this.back()
    })
  },
  methods: {
    ...mapActions(['downloadFile', 'mealOrgdetail', 'addmealOrg']),
    closeLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    back() {
      this.$router.go(-1)
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.mealOrgdetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.getFileDetail(res.data.businessLicense, 'businessLicenseUploadList')
            this.getFileDetail(res.data.professionalQualification, 'professionalQualificationUploadList')
            this.getFileDetail(res.data.photoIds, 'photoIdsUploadList')

            this.formModel.id = this.formId
            this.formModel.beOrgId = res.data.beOrgId
            this.formModel.beOrgName = res.data.beOrgName
            this.formModel.tenant = res.data.tenant.split(',').slice(2)
            this.formModel.orgName = res.data.orgName
            this.formModel.address = res.data.address
            this.formModel.standard = res.data.standard
            this.formModel.delivery = res.data.delivery
            this.formModel.deliveryFee = res.data.deliveryFee
            this.formModel.status = res.data.status
            this.formModel.mainPerson = res.data.mainPerson
            this.formModel.mainPersonTel = res.data.mainPersonTel
            this.formModel.serviceTime = res.data.serviceTime
            this.formModel.businessLicense = res.data.businessLicense
            this.formModel.professionalQualification = res.data.professionalQualification
            this.formModel.longitude = res.data.longitude
            this.formModel.latitude = res.data.latitude
            this.formModel.photoIds = res.data.photoIds
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    getFileDetail(ids, field) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this[field].push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    handleSubmit() {
      this.loading = true
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = `1,101,${params.tenant.join(',')}`

          if (this.formStatus !== 'detail') {
            this.addmealOrg(params).then(res => {
              if (res.code !== 200) {
                this.closeLoading()
                this.$message.error(res.msg)
                return
              }
              this.$message.success(this.formStatus === 'add' ? '添加成功' : '修改成功')
              this.back()
            }).catch(() => {
              this.closeLoading()
            })
          } else {
            this.back()
          }
        } else {
          this.closeLoading()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    overflow: auto;
  }
</style>
