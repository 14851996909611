<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="foodImg" slot-scope="{item}" :upload-lists="photoUploadLists" @uploadChange="item.uploadChange" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg'
export default {
  name: 'Form',
  components: { UploadImg, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      photoUploadLists: [],
      formModel: {
        orgId: '',
        foodTitle: '',
        foodImg: '',
        foodDesc: '',
        foodPrice: 0,
        status: 0
      }
    }
  },
  computed: {
    formItem() {
      return {
        'foodTitle': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255
        },
        'foodImg': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'foodImg',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'foodImg', data[0])
            } else {
              this.$set(this.formModel, 'foodImg', '')
            }
          }
        },
        'foodDesc': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '描述',
          'maxlength': 1000,
          'showWordLimit': true
        },
        'foodPrice': {
          'elColSpan': 24,
          'component': 'CustomFormNumber',
          'label': '起始价格',
          'min': 0,
          'max': 99999999,
          'precision': 2,
          'step': 10,
          'controls': true
        },
        'status': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['下架', '正常']
        }
      }
    },
    formRules() {
      return {
        foodTitle: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        foodImg: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        foodPrice: [
          { required: true, message: '价格不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['mealFoodDetail', 'mealFoodAdd', 'mealFoodUpdate']),
    handleAdd(orgId) {
      this.init('add', orgId)
    },
    handleEdit(id) {
      this.init('edit', '', id)
    },
    init(formStatus, orgId = '', id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.formModel.orgId = orgId
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.mealFoodDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.photoUploadLists = [
              {
                'id': res.data.foodImg,
                'url': res.data.foodImgUrl
              }
            ]

            this.formModel.orgId = res.data.orgId
            this.formModel.id = this.formId
            this.formModel.foodTitle = res.data.foodTitle
            this.formModel.foodImg = res.data.foodImg
            this.formModel.foodDesc = res.data.foodDesc
            this.formModel.foodPrice = res.data.foodPrice
            this.formModel.status = res.data.status
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }

          switch (this.formStatus) {
            case 'add':
              this.mealFoodAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.mealFoodUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
